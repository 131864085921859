import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connect,
  disconnect,
  fetchContract
} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { config, abi } from "./config.js"

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
// import WalletConnectApp from "./Components/WalletConnectApp.js";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  margin-right: 10px;
  margin-left: 10px;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: fit-content;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: transparent;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG] = useState(config);

  const claimNFTs = async () => {

    let cost = CONFIG.WEI_COST;

    let ethValue = blockchain.web3.utils.fromWei(cost.toString(), "ether") * Number(mintAmount);
    let value = blockchain.web3.utils.toWei(ethValue.toString(), "ether")

    let tx = {
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
      value: value,
    }

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    console.log(blockchain.web3.currentProvider.accounts)
    if (blockchain.web3.currentProvider.accounts && blockchain.web3.currentProvider.accounts.length) {
      try {
        let gas = await blockchain.smartContract.methods
          .mint(mintAmount).estimateGas(tx)
        tx.gas = blockchain.web3.utils.toHex(gas)
      } catch (err) {
        setFeedback("Insufficient Funds");
        setClaimingNft(false);
        return
      }
    }

    console.log("tx", tx)
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send(tx)
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      }).catch(err => {
        console.log(err);
        setMintAmount(1)
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account, blockchain.smartContract]);

  useEffect(() => {
    dispatch(fetchContract());
  }, [])

  console.log(blockchain.account);
  return (
    <s.Screen>
      <s.Container
      className="body-100"
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "#212429" }}
      >
        <div className="top-div">
        <a href="https://www.Bunnypunk.io/"><StyledLogo className={'mobile-logo'} alt={"logo"} src={"/config/images/logo.svg"} /></a>
<div className="nav-right">
        <a href="https://www.Bunnypunk.io/"><span className="nav-btn gradientbox">HOME</span></a>
        <a href="https://www.Bunnypunk.io/litepaper"><span className="nav-btn gradientbox">LITEPAPER</span></a>
        <a href="https://stakev2.Bunnypunk.io/"><span className="nav-btn gradientbox after-txt">STAKE</span></a>

        </div>
        </div>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>


          <s.Container className="gradientbox"
            flex={2}
            jc={"center"}
            ai={"center"}
          >

            <div className="video-div">
              <StyledImg className="scsc" alt={"example"} src={"/config/images/MintPass.gif"} />
            </div>


<div className="info-div">
            <h2 class="heading-31 h1-row-1 cros-schain-sbs">Mint Pass</h2>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)"
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)"
              }}
            >

            </s.TextDescription>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY && blockchain.account !== null ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {CONFIG.SYMBOL} COSTS {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>

                {blockchain.account === null ||
                  blockchain.smartContract === null ? (
                  <s.Container className="centerd-buttons" ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)"
                      }}
                    >
                      Connect to {CONFIG.NETWORK.NAME} Smart Chain
                    </s.TextDescription>

                    <StyledButton className="connect-btns"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect Wallet
                    </StyledButton>

                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)"
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)"
                      }}
                    >
                    </s.TextDescription>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <div className="rounded-sep">
                    <StyledRoundButton
                    className="minus"
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)"
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>

                      <StyledRoundButton
                      className="plus"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                      </div>
                    </s.Container>
                    <s.Container className="centered-btns-txt" ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                      className="connect-btns btn-secondary-connect"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Claiming pass.." : "Mint My Pass"}
                      </StyledButton>

                      <StyledButton
                      className="disconnect-wallet-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(disconnect());
                        }}
                      >
                        Disconnect Wallet
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            </div>
          </s.Container>
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)"
            }}
          >

          </s.TextDescription>
        </s.Container>
        <div className="row-footer">
          <div className="socials-footer">
          <a className="background-socials gradientbox2" href="https://pasarprotocol.io/collections/detail/10xE27934fB3683872e35b8d9E57c30978e1260c614"><StyledLogo className={'socials-logos'} alt={"logo"} src={"/config/images/pasar.svg"} /></a>
          <a className="background-socials gradientbox2" href="https://ela.city/marketplace/collections/0xe27934fb3683872e35b8d9e57c30978e1260c614"><StyledLogo className={'socials-logos'} alt={"logo"} src={"/config/images/ela.png"} /></a>
          <a className="background-socials gradientbox2" href="https://www.t.me/bunnypunkchat"><StyledLogo className={'socials-logos'} alt={"logo"} src={"/config/images/telegram.png"} /></a>
          <a className="background-socials gradientbox2" href="https://www.twitter.com/bunny_punk_nft"><StyledLogo className={'socials-logos'} alt={"logo"} src={"/config/images/twitter.png"} /></a>
          </div>
        </div>
      </s.Container>
    </s.Screen>
  );
}

export default App;
